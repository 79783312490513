/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import PageHeader from "../../components/elements/PageHeader/PageHeader";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(PageHeader, {
    title: "こんな人に学んでほしい"
  }), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "とにかく、飛行機が好きで、世界中を飛び回りたい人。"), "\n", React.createElement(_components.li, null, "外見を磨き第一印象をよくすることで、自信を持ちたい人。"), "\n", React.createElement(_components.li, null, "内面を充実させ、美しい所作を身に付けることで、自分を磨きたい人。"), "\n", React.createElement(_components.li, null, "機内や空港で必要な、英語コミュニケーション力を身に付けたい人。"), "\n", React.createElement(_components.li, null, "学校や地元で、幅広い相手に対して、気軽に声掛けすることができる人。"), "\n", React.createElement(_components.li, null, "正直、人前で話すことは苦手だけれど、そんな自分を変えてみたい人。"), "\n", React.createElement(_components.li, null, "ボランティア活動の経験があり、新しい関係や環境に積極的に飛びこむ人。"), "\n", React.createElement(_components.li, null, "日本の顔、航空会社の顔として空港で、おもてなしのプロになりたい人。"), "\n", React.createElement(_components.li, null, "世界中の人との出会いと経験を重ねて学びたい、自分の成長にどん欲な人。"), "\n", React.createElement(_components.li, null, "華やかに見えて、実は誠意と着実さに根差した、充実した職業生活を希望する人。"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
